<template>
  <div class="about-us">
    <div class="title">關於我們</div>
    <el-divider />
    <div class="content">
      春麟珠寶：高端珠寶品牌，專註精致設計，我們一直將「精致、悅己、傳承」的理念用在每一款設計中，以此傳遞「高端、優雅和氣質」。<br /><br />

      我們的設計秉承著：滿足裝飾搭配的需求，同時不乏收藏價值需求的理念。<br /><br />

      為了更好的服務客戶，呈現精彩紛呈的珠寶世界，我們更是嚴格的把關珍珠品質，同時也在豐富珠寶品類，每一件產品都帶有國家珠寶鑒定證書，一物一證，支持任意機構復檢。<br /><br />

      春麟，做您身邊的珠寶專家。<br />
    </div>
  </div>
</template>

<style lang="less" scoped>
.about-us {
  padding: 0 3.2vw;
  margin-bottom: 4vw;

  .title {
    font-size: 3.7333vw;
    font-weight: 400;
    line-height: 5.6vw;
    text-align: center;
    margin-top: 6.4vw;
  }

  .el-divider {
    margin-top: 2.8vw;
  }

  .content {
    font-size: 3.2vw;
    font-weight: 400;
    color: #3D3D3D;
    line-height: 4.8vw;
  }
}
</style>
